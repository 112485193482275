import React from 'react';
import { makeStyles } from '@material-ui/core';
import PageForbidden from './PageForbidden';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));
const PageForbiddenRoute = props => {
  const { title, statusCode, subheader, message, showButton } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <PageForbidden
        title={title ?? t('Page Forbidden')}
        statusCode={statusCode ?? '403'}
        subheader={
          subheader ?? t('Sorry, you do not have permission to view this page.')
        }
        message={message ?? t('Please contact an administrator to get access.')}
        to="/"
        buttonLabel={t("Back to Home Page")}
        showButton={showButton}
      />
    </div>
  );
};
export default PageForbiddenRoute;
