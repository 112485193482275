import React, { Suspense } from 'react';
import Routes from './Routes';
import { } from './utils/theme';
import { CssBaseline } from '@material-ui/core';
import { AuthProviderWrapper, ThemeProvider } from 'unity-fluent-library';
import { storeInitialPath } from './utils/redirect/LocalRedirectUrlStorage';
import { storeInitialTenant } from './utils/storage/UnitySessionStorage';

let App = () => {
  storeInitialPath();
  storeInitialTenant();

  return (
    <Suspense fallback="loading">
      <ThemeProvider>
        <AuthProviderWrapper>
          <CssBaseline />
          <Routes />
        </AuthProviderWrapper>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
